<template>
  <div class="category">
    <!-- <Loader :loading="loading" /> -->
    <div class="category__header">
      <h1 class="category__header_title">{{ categoryName }}</h1>
      <BaseButton
        class="category__header_button button--ml button--white button--ellipse"
        text="View Creators List"
        @click="viewArtistsList = true"
      />
    </div>

    <div class="category__body">
      <div class="category__body__artworks">
        <div class="category__body__artworks__container" ref="scrollContainer">
          <div class="category__body__artworks__container_row">
            <router-link
              v-for="(artwork, index) in categoryArtworks"
              :key="`row-${index}`"
              :to="`/art/${artwork.slug}`"
              class="category__body__artworks__container_row-item item__link"
            >
              <img :src="artwork.image" :alt="artwork.title" decoding="async" loading="lazy" />
              <div class="tooltip">
                <span class="tooltip__explore-title">Click To Explore Art</span>
                <div class="tooltip__group">
                  <!-- <span class="tooltip__group_title">{{ artwork.title }}</span>
                  <span class="tooltip__group_author">by {{ artwork.author }}</span> -->
                  <span class="tooltip__group_author">by {{ artwork.author }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <InfiniteLoading
            class="infinite-loading"
            ref="infiniteLoading"
            @infinite="loadMore"
            force-use-infinite-wrapper=".category__body__artworks__container"
          />
        </div>
        <div
          class="category__carousel__navigation_container"
          v-if="(artworksPage === 1 && hasMorePages) || artworksPage > 1"
        >
          <IconButton
            class="category__carousel__navigation_button button--control"
            :disabled="false"
            @click.native="mouseWheel({ deltaY: -100 })"
          >
            <IconArrow class="arrow--left" />
          </IconButton>
          <IconButton
            class="category__carousel__navigation_button button--control"
            :disabled="false"
            @click.native="mouseWheel({ deltaY: 100 })"
          >
            <IconArrow class="arrow--right" />
          </IconButton>
        </div>
      </div>
    </div>
    <div class="category__carousel__container">
      <h2 class="category__carousel__container_title">
        {{ getTitleMoreCategories }}
      </h2>
      <div class="category__carousel__box">
        <carousel
          ref="carousel"
          class="category__carousel__wrapper"
          centerMode
          :per-page="slidesPerPage"
          :mouseDrag="false"
          :scrollPerPage="false"
          :paginationEnabled="false"
          @page-change="pageChange"
        >
          <slide
            v-for="(category, key) in moreCategories"
            :key="`category-${key}`"
            class="category__carousel__slide"
          >
            <router-link
              :to="{ name: 'Category', params: { categoryName: category.slug } }"
              class="category__carousel__slide_link"
            >
              <img
                class="category__carousel__slide_image"
                :src="category.image"
                :alt="category.name"
                decoding="async"
                loading="lazy"
              />
            </router-link>
            <div class="category__carousel__slide_title">{{ category.name }}</div>
          </slide>
        </carousel>

        <div
          v-show="moreCategories.length > slidesPerPage"
          class="category__carousel__navigation_container"
        >
          <IconButton
            class="category__carousel__navigation_button button--control"
            :disabled="disabledPrev"
            @click.native="goToSlide('prev')"
          >
            <IconArrow class="arrow--left" />
          </IconButton>
          <IconButton
            class="category__carousel__navigation_button button--control"
            :disabled="disabledNext"
            @click.native="goToSlide('next')"
          >
            <IconArrow class="arrow--right" />
          </IconButton>
        </div>
      </div>
    </div>

    <div class="category__buttons-box">
      <BaseButton
        v-if="hasMorePages"
        class="button--white button--oval"
        text="Load More"
        @click.native="loadMore"
      />
      <BaseButton
        class="button--outline-white button--oval"
        text="Back to Top"
        @click.native="backToTop"
      />
    </div>
    <PopUp
      class="view-artists-list modal--fullscreen"
      :show="viewArtistsList"
      :closeCircle="true"
      :closeBold="true"
      @hideModal="viewArtistsList = false"
    >
      <div class="view-artists-list__inner">
        <p class="view-artists-list__header">{{ categoryName }} - Creators Included</p>
        <div class="view-artists-list__body" v-if="artistsList.length > 0">
          <div
            v-for="(column, index) in sortedArtistsList"
            :key="`column-${index}`"
            class="view-artists-list__column"
          >
            <router-link
              v-for="(item, index) in column"
              :key="`item-${index}`"
              class="view-artists-list__column-item"
              :to="`/creators/${item.slug}`"
            >
              <span class="view-artists-list__column-name">
                {{ item.full_name }} ({{ item.artworks_count }})
              </span>
            </router-link>
          </div>
        </div>
        <div class="view-artists-list__body" v-else>
          <div class="view-artists-list__column">
            <span class="view-artists-list__column-name"> No Creators </span>
          </div>
        </div>
      </div>
    </PopUp>
  </div>
</template>

<script>
import { scroller } from "vue-scrollto/src/scrollTo";
import { Carousel, Slide } from "vue-carousel";
import { sortedColumns } from "@/helper.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Loader from "@/components/Loader.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconArrow from "@/components/icons/IconArrowTop.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import InfiniteLoading from "vue-infinite-loading";
import PopUp from "@/components/PopUp.vue";

export default {
  components: {
    Carousel,
    Slide,
    Loader,
    IconButton,
    IconArrow,
    BaseButton,
    InfiniteLoading,
    PopUp,
  },

  data() {
    return {
      loading: false,
      slidesPerPage: 5,
      disabledPrev: true,
      disabledNext: false,
      artworksPage: 1,
      pageTitle: "GH - Category",
      viewArtistsList: false,
      sortedArtistsList: [],
    };
  },
  head: {
    title: function () {
      return {
        inner: this.pageTitle,
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },
  watch: {
    "$route.path": {
      async handler() {
        this.loading = true;
        let breadcrumbs = [];
        this.slidesPerPage = 5;
        breadcrumbs = [
          {
            title: "Home",
            to: "/",
          },
          {
            title: "Categories",
            to: "/categories",
          },
          {
            title: `${this.$route.params.categoryName}`,
            to: `/art/${this.$route.params.categoryName}`,
          },
        ];
        window.scrollTo(0, 0);
        let page = 1;
        let success = await this.apiGetSpecificCategory({
          slug: this.$route.params.categoryName,
          page,
        });
        this.disabledNext = this.moreCategories.length < this.slidesPerPage;
        if (success) {
          this.artworksPage = page;
        }
        this.setBreadcrumbs(breadcrumbs);
        this.pageTitle = `GH - ${this.categoryName}`;
        this.$emit("updateHead");
        this.$refs.infiniteLoading.status = 0;
        this.$refs.infiniteLoading.scrollHandler();
        this.loading = false;
      },
    },
  },

  computed: {
    ...mapGetters("publicCategories", {
      categoryName: "getCategoryName",
      categoryType: "getCategoryType",
      categoryArtworks: "getCategoryArtworks",
      moreCategories: "getMoreCategories",
      hasMorePages: "getHasMorePages",
      artistsList: "getArtistsList",
    }),
    getColumnsAmount() {
      const width = window.innerWidth;
      if (width < 540) {
        return 1;
      } else if (width < 768) {
        return 2;
      } else if (width < 1024) {
        return 3;
      } else {
        return 4;
      }
    },
    getTitleMoreCategories() {
      if (this.$route.params.categoryName === "collection") return "All Categories";
      else if (this.categoryType.title === "Media Types") return "All Media Types";
      return "More Categories";
    },
  },

  async created() {
    this.loading = true;
    let breadcrumbs = [];
    this.slidesPerPage = 5;
    breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Categories",
        to: "/categories",
      },
      {
        title: `${this.$route.params.categoryName}`,
        to: `/art/${this.$route.params.categoryName}`,
      },
    ];
    await this.apiGetSpecificCategory({
      slug: this.$route.params.categoryName,
      page: this.artworksPage,
    });
    this.disabledNext = this.moreCategories.length < this.slidesPerPage;
    this.setBreadcrumbs(breadcrumbs);
    this.sortedArtistsList = sortedColumns(this.artistsList, this.getColumnsAmount); //to get columns
    this.pageTitle = `GH - ${this.categoryName}`;
    this.$emit("updateHead");
    this.$refs.infiniteLoading.status = 0;
    this.$refs.infiniteLoading.scrollHandler();
    this.loading = false;
  },

  methods: {
    ...mapActions("publicCategories", ["apiGetSpecificCategory"]),
    ...mapMutations(["setBreadcrumbs"]),

    goToSlide(direction) {
      let carousel = this.$refs.carousel;

      switch (direction) {
        case "prev": {
          carousel.goToPage(carousel.getPreviousPage());
          break;
        }
        case "next": {
          carousel.goToPage(carousel.getNextPage());
          break;
        }
      }
    },

    pageChange(slideIndex) {
      this.disabledPrev = slideIndex === 0;
      this.disabledNext = slideIndex + 1 === this.moreCategories.length;
      //this.disabledNext = (slideIndex + 1) * this.slidesPerPage > this.moreCategories.length;
    },

    async changePage(direction) {
      let page;
      switch (direction) {
        case "prev": {
          page = this.artworksPage - 1;
          break;
        }
        case "next": {
          page = this.artworksPage + 1;
          break;
        }
      }
      let success = await this.apiGetSpecificCategory({
        slug: this.$route.params.categoryName,
        page,
      });
      if (success) {
        this.artworksPage = page;
      }
    },

    async loadMore($state) {
      let success = false;
      let page;
      if (this.hasMorePages) {
        page = this.artworksPage + 1;
        success = await this.apiGetSpecificCategory({
          slug: this.$route.params.categoryName,
          page,
          mobile: true,
        });
      }
      if (success) {
        this.artworksPage = page;
      }
      this.sortedArtistsList = sortedColumns(this.artistsList, this.getColumnsAmount); //to get columns
      if (this.hasMorePages) {
        $state.loaded();
      } else {
        $state.complete();
      }
      this.busy = false;
    },

    backToTop() {
      const firstScrollTo = scroller();
      firstScrollTo(".header_breadcrumbs");
    },
    mouseWheel(event) {
      const scrollContainer = this.$refs.scrollContainer;
      if (event.deltaY > 0) {
        const isNotScrollEnd =
          scrollContainer.scrollWidth > scrollContainer.scrollLeft + scrollContainer.offsetWidth;
        if (isNotScrollEnd) {
          if (!this.scrollAnimation) {
            this.scrollAnimation = true;
            this.interval = setInterval(() => {
              scrollContainer.scrollLeft += 5;
            }, 1);
            const vm = this;
            this.timeout = setTimeout(() => {
              clearInterval(vm.interval);
              this.scrollAnimation = false;
            }, 300);
          }
        }
      } else {
        const isNotScrollStart = scrollContainer.scrollLeft > 0;
        if (isNotScrollStart) {
          if (!this.scrollAnimation) {
            this.scrollAnimation = true;
            this.interval = setInterval(() => {
              scrollContainer.scrollLeft -= 5;
            }, 1);
            const vm = this;
            this.timeout = setTimeout(() => {
              clearInterval(vm.interval);
              this.scrollAnimation = false;
            }, 300);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $lightBlack;
  min-height: 100vh;
  min-width: 100vw;
  font-size: 2.1rem;
  line-height: 3.5rem;
  @media only screen and (max-width: $xs) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  &__header {
    margin-top: 23.7rem;
    width: 100%;
    padding: 0 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
    }
    &_title {
      display: inline-block;
      text-align: center;
      font-family: $fontBodoni;
      font-size: 6.2rem;
      line-height: 1.5;
      @media only screen and (max-width: $xs) {
        font-size: 4.2rem;
        margin-bottom: 10px;
      }
    }
    &_button {
      width: max-content;
      min-width: 27.4rem;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4.4rem 0 20rem;
    width: 100%;
    @media only screen and (max-width: $xs) {
      padding: 3.8rem 0 0;
    }

    &__artworks {
      position: relative;
      width: 100%;
      .category__carousel__navigation_container {
        position: absolute;
        padding: 0 4rem;
        top: 50%;
        left: 0;
        width: 100vw;
        transform: translateY(-50%);
        @media only screen and (max-width: $xs) {
          display: none;
        }
      }
      &__container {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 100vw;
        overflow-x: scroll;
        scroll-snap-type: x;
        padding: 0 4rem 1.5rem 12rem;
        @media only screen and (max-width: $xs) {
          margin-top: 0;
          padding: 0;
        }

        &_row {
          position: relative;
          display: grid;
          grid-template-columns: 22rem;
          grid-template-rows: 21rem 21rem 21rem;
          grid-auto-flow: column;
          column-gap: 15px;
          row-gap: 15px;
          width: max-content;
          @media only screen and (max-width: $xs) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            grid-auto-flow: row;
            row-gap: 0;
            width: 100%;
          }
          &-item {
            position: relative;
            height: 100%;
            width: 21rem;
            &:nth-of-type(3n + 1) {
              margin-left: -4rem;
            }
            &:nth-of-type(3n) {
              margin-left: -8rem;
            }
            @media screen and (max-width: $xs) {
              width: 100%;
              &:nth-of-type(n) {
                margin-left: 0;
              }
            }
            img {
              height: 100%;
              width: 28rem;
              @media only screen and (max-width: $xs) {
                width: 100%;
                height: 100vw;
              }
            }
            .tooltip {
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              transition: all 0.3s ease;
              background: linear-gradient(transparent, 35%, rgba(0, 0, 0, 0.9));
              width: 100%;
              height: 100%;
              opacity: 0;

              &__explore-title {
                font-family: $fontBodoni;
                font-size: 1.5rem;
              }

              &__group {
                display: flex;
                flex-direction: column-reverse;
                position: absolute;
                padding: 0 1.6rem 2rem 1.6rem;
                width: 100%;
                left: 0;
                bottom: 0;
                height: 10rem;

                &_title {
                  font-family: $fontBodoni;
                  font-size: 3rem;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  line-clamp: 1;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }

                &_author {
                  font-size: 1.6rem;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  line-clamp: 1;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }
              @media only screen and (max-width: $sm) {
                opacity: 1;
              }
            }

            .item__title {
              display: none;
              @media only screen and (max-width: $xs) {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                line-clamp: 1;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }

            &:hover {
              .tooltip {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  &__carousel {
    &__container {
      margin-bottom: 3.5rem;
      width: 100%;
      @media only screen and (max-width: $xxs) {
        display: none;
      }

      &_title {
        font-family: $fontBodoni;
        font-size: 3.8rem;
        line-height: 4.8rem;
        margin-bottom: 3.8rem;
        margin-left: 1.5rem;
      }
    }

    &__box {
      position: relative;
    }

    &__slide {
      padding: 0 1.2rem;
      &_link {
        display: block;
        text-decoration: none;
      }
      &_image {
        width: 100%;
        height: 30rem;
        object-fit: cover;
        filter: brightness(0.42);
        cursor: pointer;
      }

      &_title {
        position: absolute;
        font-family: $fontBodoni;
        bottom: 12px;
        margin-left: 20px;
        font-size: 3.1rem;
        line-height: 4.4rem;
        text-transform: capitalize;
      }
    }

    &__navigation {
      &_container {
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        top: calc(50% - 3.4rem);
        padding: 0 5rem;
        pointer-events: none;
        @media only screen and (max-width: $xxs) {
          padding: 0 1.7rem;
        }
      }

      &_button {
        pointer-events: auto !important;
        .arrow {
          &--left {
            margin-right: 4px;
            transform: rotate(-90deg);
          }

          &--right {
            margin-left: 4px;
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &__buttons-box {
    display: none;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0 7rem;
    width: 100%;
    @media only screen and (max-width: $xs) {
      display: flex;
    }

    .button--oval {
      padding: 12px 18px;
      width: 50%;
    }

    .button--outline-white {
      margin-top: 1.8rem;
    }
  }
}

.view-artists-list {
  &__inner {
    padding-top: 7.5rem;
    width: 84vw;
  }
  &__header {
    font-family: $fontBodoni;
    font-style: normal;
    font-weight: 500;
    font-size: 6.2rem;
    line-height: 1.53;
    letter-spacing: -0.02em;
    color: $black;
    margin-bottom: 6rem;
    @media only screen and (max-width: $xs) {
      font-size: 4.2rem;
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $xs) {
      width: 100%;
      display: block;
      text-align: center;
    }
  }
  &__column {
    &-item {
      display: block;
      text-decoration: none;
    }
    &-name {
      font-family: $fontOpenSans;
      font-style: normal;
      font-weight: 400;
      font-size: 2.1rem;
      line-height: 1.67;
      letter-spacing: -0.35px;
      color: $black;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>

